/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import vuetify from '../plugins/vuetify'
import ActionCableVue from 'actioncable-vue';
import '../assets/css/general.css.scss';
import axios from "axios";

import App from '../app.vue'
import LineTable from '../components/line_table.vue'
import LineDialog from '../components/line_dialog.vue'

let localization = require('./localization.js')


Vue.use(TurbolinksAdapter)
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectImmediately: true,
});
axios.defaults.baseURL = "https://www.filalibre.com"
Vue.prototype.$http = axios
Vue.prototype.$t = localization.$lang

Vue.component('app', App)
Vue.component('line-table', LineTable)
Vue.component('line-dialog', LineDialog)

document.addEventListener('turbolinks:load', () => {
  axios.defaults.headers.common = {
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    'Accept': 'application/json'
  };

  const app = new Vue({
    vuetify,
    el: '[data-behavior="vue"]'
  })
})