<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small rounded color="primary" dark v-bind="attrs" v-on="on">QR</v-btn>
      </template>
      <v-img :src="line.qr_path"></v-img>
      <v-btn large color="primary" dark v-on:click="omitTurn">
        {{$t(locale).omit}}
      </v-btn>
    </v-dialog>
</template>

<script>
  export default {
    props: ['line'],
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      locale() {
        return window.navigator.language ? window.navigator.language : "en"
      }
    },
    methods: {
      omitTurn: function () {
        this.$http.patch(`lines/${this.line.id}/omite`).then(() => {
            this.dialog = false;
            window.open(this.print());
          }
        )
      },
      print: function () {
        return window.location.origin + `/lines/${this.line.id}/print`
      }
    },
  }
</script>