<template>
  <v-app>
    <v-data-table
      :headers="headers"
      :items="data"
      class="elevation-1"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      dark
    >
      <template v-slot:item.number={item}>
        <v-chip color='blue' dark>{{ item.number }}</v-chip>
      </template>
      <template v-slot:item.current_number={item}>
        <v-chip color='yellow' light>{{ item.current_number }}</v-chip>
      </template>
      <template v-slot:item.waiting_time={item}>{{waitingTime(item)}}</template>
      <template v-slot:item.actions={item}>
        <line-dialog :line=item v-if="user['super?'] || user['general?'] || user['external?']"></line-dialog>
        <v-btn small rounded color="primary" dark v-on:click={nextTurn(item)} v-if="user['super?'] || user['general?'] || user['internal?']">
          {{$t(locale).next}}
        </v-btn>
      </template>
    </v-data-table>
  </v-app>
</template>

<script>
export default {
  props: ["lines", "user"],
  data() {
    return {
      data: this.lines
    };
  },
  computed: {
    locale() {
      return window.navigator.language ? window.navigator.language : "en"
    },
    headers() {
      let l = this.locale;
      let $t= this.$t;
      return [
        { text: $t(l).line, value: "letter", align: "center" },
        { text: $t(l).number, value: "number", align: "center" },
        { text: $t(l).current_number, value: "current_number", align: "center" },
        { text: $t(l).waiting_time, value: "waiting_time", align: "center" },
        { text: $t(l).actions, value: "actions", align: "center" }
      ];
    }
  },
  methods: {
    waitingTime: function (line) {
      return (line.current_number - line.number) * line.waiting_time;
    },
    nextTurn: function (line) {
      this.$http.put(`lines/${line.id}`);
    },
    refreshLine: function(line) {
      this.data.forEach(function (el) {
        if(el.id === line.id) {
          el.number = line.number
          el.current_number = line.current_number
        }
      })
    }
  },
  channels: {
    LinesChannel: {
      connected () {},
      received (data) {
        this.refreshLine(data.line)
      },
    }
  },
  mounted() {
    this.$cable.subscribe({
        channel: 'LinesChannel',
        roomId: document.getElementById('room_messages').dataset.channelRoom
      }
    );
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  td, th {
    vertical-align: middle;
  }
}
</style>